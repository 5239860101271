<template>
  <list-container-widget
    id="chat"
    title="Chat"
    :app-id="97"
    :view-more-location="getLocation('chat')"
    :disable-view-more="false"
    :size="100"
    stretch
    hidden-actions
    class="d-flex"
  >
    <ChatArea2 />
  </list-container-widget>
</template>

<script>

import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import ChatArea2 from '../components/ChatArea2.vue';

export default {
  name: 'ChatWidget',
  components: {
    ListContainerWidget,
    ChatArea2,
  },
  mixins: [WidgetLayoutMixin],
};
</script>
